<template>
  <div>
    <br />
    <div class="text-center">
      <div class="div-molded">
        <Molded>
          <div class="msg-success">{{ msgSuccess }}</div>
          <div class="icon-success">
            <i class="fa-light fa-circle-check"></i>
          </div>
          <div>
            <Button
              _key="btnPrint"
              type="info"
              title="Imprimir"
              classIcon="fa-solid fa-print-magnifying-glass"
              size="small"
              :clicked="executePrint"
            />
          </div>
          <br />
        </Molded>
      </div>
    </div>
    <PrintPdv v-if="print" :rentId="rentId" :onHideModal="onHideModal" />
  </div>
</template>

<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import PrintPdv from "./PrintPdv.vue";

import { mapMutations } from "vuex";

export default {
  name: "SuccessPdvRent",
  components: {
    Button,
    Molded,
    PrintPdv,
  },
  props: {
    msgSuccess: String,
    rentId: String,
  },
  data() {
    return {
      print: false,
    };
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    executePrint() {
      this.print = true;
      this.removeLoading(["btnPrint"]);
    },
    onHideModal() {
      this.print = false;
    },
  },
};
</script>

<style scoped>
.div-molded {
  width: 550px;
  margin: auto;
}

.msg-success {
  font-size: 18px;
}

.icon-success {
  font-size: 55px;
  color: #38a169;
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>